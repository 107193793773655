/* Global Styles */
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #0a0f1f;
    color: #e0e0e0;
    line-height: 1.6;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Header Styles */
header {
    background: linear-gradient(135deg, #3b82f6, #9333ea);
    color: #fff;
    padding: 40px;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    width: 100%;
}

header h1 {
    margin: 0;
    font-size: 3em;
    font-weight: 800;
    letter-spacing: 2px;
}

header p {
    font-size: 1.2em;
    margin-top: 10px;
    color: #d1d5db;
}

/* Notification Styles */
#notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background: rgba(31, 41, 55, 0.9);
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #3b82f6;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    color: #d1d5db;
    max-width: 300px;
    z-index: 1000;
}

#notification ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0 0;
}

#notification li {
    margin-bottom: 10px;
}

#notification a {
    color: #93c5fd;
    text-decoration: none;
    font-weight: bold;
}

#notification a:hover {
    text-decoration: underline;
}

/* Main Styles */
main {
    padding: 50px;
    max-width: 1000px;
    margin: 50px auto;
    background: rgba(15, 23, 42, 0.8);
    border-radius: 20px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.7);
    text-align: center;
}

/* Section Styles */
section {
    margin-bottom: 40px;
    padding: 30px;
    border-radius: 15px;
    background: rgba(31, 41, 55, 0.9);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    border: 1px solid #3b82f6;
}

section h2 {
    margin-top: 0;
    font-size: 2.2em;
    font-weight: 700;
    color: #93c5fd;
    border-bottom: 2px solid #3b82f6;
    padding-bottom: 10px;
}

section p {
    margin-bottom: 20px;
    color: #d1d5db;
}

/* Input Styles */
.input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

input[type="file"],
input[type="text"],
input[type="email"] {
    width: 80%;
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 25px;
    border-radius: 10px;
    border: 2px solid #3b82f6;
    background: #1f2937;
    color: #e0e0e0;
    font-size: 1em;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.4);
    transition: border-color 0.3s, box-shadow 0.3s;
    text-align: center;
}

input[type="file"]:focus,
input[type="text"]:focus,
input[type="email"]:focus {
    border-color: #9333ea;
    outline: none;
    box-shadow: 0 0 10px rgba(147, 51, 234, 0.5);
}


/* Button Styles */
button {
    background: linear-gradient(135deg, #3b82f6, #9333ea);
    color: #fff;
    border: none;
    padding: 15px 25px;
    font-size: 1.1em;
    font-weight: 700;
    cursor: pointer;
    border-radius: 12px;
    transition: background 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
}

button:hover {
    background: linear-gradient(135deg, #9333ea, #3b82f6);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.7);
}

/* Progress Bar */
#progress-bar {
    display: none;
    width: 80%;
    background-color: #1f2937;
    height: 25px;
    border-radius: 10px;
    overflow: hidden;
    margin: 20px auto;
    border: 2px solid #3b82f6;
}

#progress-bar-inner {
    width: 0;
    height: 100%;
    background: linear-gradient(135deg, #3b82f6, #9333ea);
    text-align: center;
    color: #fff;
    line-height: 25px;
    border-radius: 10px;
    transition: width 0.4s ease;
}

/* Loading Indicator Styles */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(31, 41, 55, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

.loading-spinner {
    border: 8px solid #e5e7eb;
    border-top: 8px solid #3b82f6;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Footer Styles */
footer {
    margin-top: 60px;
    text-align: center;
    padding: 30px;
    font-size: 1em;
    color: #93c5fd;
    background: #0a0f1f;
    border-top: 2px solid #3b82f6;
    width: 100%;
}

/* Utility Classes */
.hidden {
    display: none;
}

.text-center {
    text-align: center;
}

.wallet-connected {
    color: green;
}
